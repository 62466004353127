<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="loading">
            <b-row>
                <b-col lg="12" sm="12">
                    <CCard>
                        <CCardHeader>
                            <div class="row">
                                <div class="col-md-6">
                                    <strong>App Update Setting</strong>
                                </div>
                            </div>
                        </CCardHeader>
                        <CCardBody>
                            <div class='row'>
                                <b-col lg="12" sm="12">
                                    
                                        <ValidationObserver ref="form1"  v-slot="{ handleSubmit, reset }">
                                            <b-form  @submit.prevent="handleSubmit(register(update))" @reset.prevent="reset" >
                                                <div class='row'>
                                                    <b-col lg="12" sm="12">
                                                        <ValidationProvider name="Notification Title" vid="name" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Notification Title
                                                            </template>
                                                            <b-form-input
                                                                type="text"
                                                                id="name"
                                                                v-model="update.name"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="12" sm="12">
                                                        <ValidationProvider name="Description" vid="description" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Description
                                                            </template>
                                                            <b-form-textarea
                                                                id="description"
                                                                v-model="update.description"
                                                                rows="8"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                </div>
                                                <div class="text-right">
                                                    <b-button type="submit" variant="primary" style="margin-top:40px" class="mr-2">Send Now</b-button>
                                                </div>
                                            </b-form>
                                        </ValidationObserver>
                                    
                                </b-col>                                
                            </div>
                        </CCardBody>
                    </CCard>
                </b-col>
            </b-row>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { baseUrl } from '../../config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import iziToast from 'izitoast';

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (typeof this.$route.query.notification_id !=='undefined') {
        this.getData(this.$route.query.notification_id)
    }
  },
  data () {
    return {
      update: {
        name: '',
        description: ''
      },
      editId: ''
    }
  },
  computed: {
	loading () {
		return this.$store.state.static.loading
	}
  },
  methods: {
    getData (id) {
        this.$store.dispatch('mutedLoad', { loading: true})
        RestApi.getData(baseUrl, 'api/notifications/show/'+id).then(response => {
            if (response.success) {
                this.update = response.data
            }
            this.$store.dispatch('mutedLoad', { loading: false })
        })
    },
    async register (Obj) {
        this.$store.dispatch('mutedLoad', { loading: true })
        let result = null
        result = await RestApi.postData(baseUrl, `api/notifications/send`, Obj)
        this.$store.dispatch('mutedLoad', { loading: false })
        if (result.success) {
			iziToast.success({
				title: 'Success',
				message: result.message
			})
        } else {
            this.$refs.form.setErrors(result.errors)
        }
    }
  }
}
</script>
